import { Newsletter } from '../components/Newsletter/Newsletter'

const NewsletterPage = () => {
  return (
    <div>
      <Newsletter />
    </div >
  )
}

export default NewsletterPage